export const eventsList = [

    // Chicago
    {
        'center': 'Chicago',
        'name': "diwali23",
        'displayName': "New Year Padharamni - 2023",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Not a Santsangi family",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSanyuktaCategory': false,
        'showSOSorting': true,
        'active': false
    },
    {
        'center': 'Chicago',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Not a Santsangi family",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSanyuktaCategory': false,
        'showSOSorting': true,
        'active': true
    },
    {
        'center': 'Chicago',
        'name': "general24",
        'displayName': "Sant Padharamni General - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'active': true
    },


    // Other Centers
    {
        'center': 'Bloomington',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSOSorting': true,
        'active': true
    },
    {
        'center': 'Crystal Lake',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSOSorting': true,
        'active': true
    },
    {
        'center': 'Evansville',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSOSorting': true,
        'active': true
    },
    {
        'center': 'Indianapolis',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSOSorting': true,
        'active': true
    },
    {
        'center': 'Munster',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSOSorting': true,
        'active': true
    },
    {
        'center': 'Iowa City',
        'name': "diwali24",
        'displayName': "New Year Padharamni - 2024",
        'cancelReasons': [
            "Not available during Padharamni timeslots",
            "Not interested in doing Padharamni",
            "Schedule at a later time",
            "Padharamni booked under another family member",
            "Family moved out"
        ],
        'showSOSorting': true,
        'active': true
    },
]