// Vendor Files
import React from "react";
import { Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";
import * as moment from 'moment-timezone';

// Custom Components
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import NotFoundContent from "../../components/NotFoundContent"
import PadharamniSchedular from "./PadharamniSchedular"
import PadharamniViewList from "./PadharamniViewList"

// Events List
import { eventsList } from './eventList';

// Image Files

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Padharamni extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selEvent: {},
      displaySchedular: false,
      displayFamilies: false,
      displaySantPadharamnis: false,
      displayNotFound: false,
      karyakarCell: "",
      santName: "",
      familyList: [],
      padharamniList: [],
      showLoader: false
    };
    this.getViewPadharamnis = this.getViewPadharamnis.bind(this);
    this.getSantPadharamnis = this.getSantPadharamnis.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('filterFamilyValue');
    this.checkEvent();
  }

  checkEvent() {
    let events = eventsList;
    let { event_name, center_name } = this.props.params;
    if (event_name && center_name) {
      let sel_event = events.filter(event => event.center.toLowerCase() === center_name.toLowerCase() && event.name.toLowerCase() === event_name.toLowerCase())[0];
      if (sel_event && sel_event.active) {
        let displaySchedular = this.checkParams();
        this.setState({ selEvent: sel_event, displaySchedular: displaySchedular });
      } else {
        this.setState({ displayNotFound: true });
      }
    }
    else {
      let displayNotFound = this.checkParams();
      if (displayNotFound) {
        this.setState({ displayNotFound: true });
      }
    }
  }

  checkParams() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get('karyakar')) {
      this.setState({ karyakarCell: params.get('karyakar') }, () => {
        this.getViewPadharamnis();
      });
      return false;
    }
    else if (params.get('sant')) {
      this.setState({ santName: params.get('sant') }, () => {
        this.getSantPadharamnis(moment().format('YYYY-MM-DD'));
      });
      return false;
    }
    return true;
  }

  getViewPadharamnis() {
    // let url = `http://localhost:5004/api/padharamni/view/karyakar`  // Dev testing
    let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/view/karyakar` // Production
    let params = {
      karyakarCell: this.state.karyakarCell,
      eventName: this.state.selEvent.name,
    }
    this.setState({ showLoader: true, displayFamilies: false });
    axios.get(url, { params }).then((res) => {
      this.setState({ displayFamilies: true, familyList: res.data.data.familyList, padharamniList: res.data.data.padharamniList });
      this.setState({ showLoader: false });
    }, (err) => {

    });
  }

  getSantPadharamnis(selectedDate) {
    // let url = `http://localhost:5004/api/padharamni/view/sant`  // Dev testing
    let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/view/sant` // Production
    let params = {
      santName: this.state.santName,
      eventName: this.state.selEvent.name,
      selectedDate: selectedDate
    }
    this.setState({ showLoader: true, displaySantPadharamnis: false });
    axios.get(url, { params }).then((res) => {
      this.setState({ displaySantPadharamnis: true, familyList: res.data.data.familyList, padharamniList: res.data.data.padharamniList });
      this.setState({ showLoader: false });
    }, (err) => {

    });
  }

  render() {
    return (
      <div>
        <Header metaPage="padharamni"></Header>

        {this.state.displayNotFound && <NotFoundContent></NotFoundContent>}

        {!this.state.displayNotFound &&
          <div className="events sectionCont schedularCont">
            <h1 className="title">{this.state.selEvent.displayName}</h1>
            {this.state.displaySchedular && <p className="sub-title"><i>Please select your zone from the list below to schedule padharamnis for haribhaktos.</i></p>}
            {(this.state.karyakarCell || this.state.santName) && <p className="sub-title"><i>Please view your scheduled padharamnis below and filter by dates.</i></p>}
            <br />
            <Container>
            {this.state.displaySchedular && <PadharamniSchedular selEvent={this.state.selEvent}></PadharamniSchedular>}
            {this.state.showLoader &&
                <Spinner animation="border" role="status">
                </Spinner>
            }
            {this.state.displayFamilies && 
                <PadharamniViewList tableList={this.state.familyList} selEvent={this.state.selEvent} padharamniList={this.state.padharamniList} refreshList={this.getViewPadharamnis}></PadharamniViewList>
            }
            {this.state.displaySantPadharamnis && 
                <PadharamniViewList santTable={true} tableList={this.state.familyList} selEvent={this.state.selEvent} padharamniList={this.state.padharamniList} refreshList={this.getSantPadharamnis}></PadharamniViewList>
            }
            </Container>
          </div>
        }

        <Footer></Footer>
      </div>
    );
  }
}

export default withParams(Padharamni);
