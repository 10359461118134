// Vendor Files
import React from "react";
import { Button, Table, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";
import { ChatSquareFill } from 'react-bootstrap-icons';

// Events List
import { eventsList } from './eventList';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ViewPastNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotesModal: false,
            familyNotes: []
        };
    }

    fetchNotes() {
        let personId = this.props.personId;
        // let url = `http://localhost:5004/api/padharamni/pastNotes`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/pastNotes` // Production
        let params = {
            personId: personId,
            eventName: this.props.selEvent.name || this.props.selEvent
        }
        axios.get(url, { params }).then((res) => {
            this.setState({ familyNotes: res.data.data });
        }, (err) => {
            
        });
    }

    handleOpen() {
        this.setState({ showNotesModal: true });
        this.fetchNotes();
    }

    handleClose() {
        this.setState({ showNotesModal: false });
    }

    getEventName(center_name, event_name) {
        let events = eventsList;
        let sel_event = events.filter(event => event.center.toLowerCase() === center_name.toLowerCase() && event.name.toLowerCase() === event_name.toLowerCase())[0];
        return sel_event.displayName;
    }

    render() {
        return (
            <>
                <ChatSquareFill className="copyLink" onClick={() => this.handleOpen()}></ChatSquareFill>
                <Modal show={this.state.showNotesModal} onHide={() => this.handleClose()} size="md" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Table striped className="padharamniTable">
                            <tbody>
                                {this.state.familyNotes.map((note) => (
                                    <tr key={this.getEventName(note.Center, note.EventName)}>
                                        <td>
                                            <b>{this.getEventName(note.Center, note.EventName)}</b><br/>
                                            {note.Notes}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            
        );
    }
}

export default withParams(ViewPastNotes);
