// Vendor Files
import React from "react";
import { useParams } from "react-router";

// Form Components
import RSVPEvent from "./formsList/rsvpEvent";
import RSVPSSM from "./formsList/rsvpSSM"
import RSVPStudentSabha from "./formsList/rsvpStudentSabha"
import RSVPSamarpan from "./formsList/rsvpSamarpan";
import RSVPMultiEvent from "./formsList/rsvpMultiEvent";
import RSVPCustomERS from "./formsList/rsvpCustomERS";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class MainForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forms: {
                rsvpEvent: RSVPEvent,
                rsvpSSM: RSVPSSM,
                rsvpMultiEvent: RSVPMultiEvent,
                rsvpStudentSabha: RSVPStudentSabha,
                rsvpSamarpan: RSVPSamarpan,
                rsvpCustomERS: RSVPCustomERS
            }
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                {React.createElement(this.state.forms[this.props.formName], {formData: this.props.formData, hideForm: this.props.hideForm})}
            </>
        );
    }
}

export default withParams(MainForm);