// Vendor Files
import React from "react";
import { Button, Form, Table, Alert, Modal, InputGroup, Card, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from "react-router";
import * as moment from 'moment-timezone';
import { SortAlphaDown, SortNumericDown, PersonFillAdd } from 'react-bootstrap-icons';

// Custom Components
import ViewFamilyMembers from "./ViewFamilyMembers"
import ViewPastNotes from "./ViewPastNotes"

// Image Files
import pencil_icon from "../../assets/images/pencil.png";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PadharamniFamilyList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            familyFilterValue: "",
            filteredFamilyList: [],
            showEditModal: false,
            editFamilyDetails: {},
            hideTimes: true,
            hideSession: true,
            availSessions: [],
            availTimes: [],
            bookedTimes: [],
            registerDisableBtn: false,
            registerWarningText: "",
            registerShowWarning: false,
            deleteSelected: false,
            sortValue: 'default'
        };
    }

    componentDidMount() {
        this.setState({ filteredFamilyList: this.props.tableList });
        this.setState({ familyFilterValue: localStorage.getItem('filterFamilyValue') ? localStorage.getItem('filterFamilyValue') : "", sortValue: localStorage.getItem('sortValue') ? localStorage.getItem('sortValue') : "default"  },
        () => {
            this.filterFamily(this.state.familyFilterValue);
        });
        localStorage.removeItem('filterFamilyValue');
        localStorage.removeItem('sortValue');
    }

    editFamily(familyDetails) {
        this.handleResetValues();
        let family = { ...familyDetails };
        family.Date = family.Date ? family.Date : "";
        family.SelectedTime = family.SelectedTime ? family.SelectedTime : "";
        family.Notes = family.Notes ? family.Notes : "";
        family.CancelReason = family.CancelReason ? family.CancelReason : "";
        if (family.PadharamniStatus === 'cancelled') {
            this.setState({deleteSelected: true});
            family.Date = 'delete';
        }
        this.setState({
            editFamilyDetails: family, showEditModal: true
        }, () => {
            if (family.Date.length > 0) {
                this.getSessions(family.Date);
            }
        });
    }

    handleClose(save = false) {
        if (save) {
            localStorage.setItem('filterFamilyValue', this.state.familyFilterValue);
            localStorage.setItem('sortValue', this.state.sortValue);
            this.props.refreshList(this.props.padharamniList[0].ZoneName, this.state.sortValue);
        }
        this.setState({ showEditModal: false, editFamilyDetails: {} });
    }

    getSOFilteredFamilies(filterValue) {
        if (filterValue === 'All') {
            return this.props.tableList;
        }
        else if (filterValue === 'Unassigned') {
            return this.props.tableList.filter(
                (family) => (!family.SOKaryakar)
            );
        }
        else {
            return this.props.tableList.filter(
                (family) => (family.SOKaryakar === filterValue)
            );
        }
    }

    filterFamily(filterValue) {
        this.setState({ familyFilterValue: filterValue });

        if (this.state.sortValue === 'so') {
            var soFamilies = this.getSOFilteredFamilies(filterValue);
            this.setState({ filteredFamilyList: soFamilies });
            return;
        }

        if (filterValue.length < 1) {
            this.setState({ filteredFamilyList: this.props.tableList });
            return;
        }

        let filteredFamilies = this.props.tableList.filter(
            (family) => (family.FirstName.toLowerCase() + " " + family.LastName.toLowerCase()).includes(filterValue.toLowerCase())
        );

        this.setState({ filteredFamilyList: filteredFamilies });
    }

    getBookedTimes(selDate, session, santName1) {
        // let url = `http://localhost:5004/api/padharamni/bookedTimes`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/bookedTimes` // Production
        let params = {
            eventName: this.props.padharamniList[0].EventName,
            personId: this.state.editFamilyDetails.PersonId,
            selectedDate: selDate,
            session: session,
            santName1: santName1
        }
        axios.get(url, { params }).then((res) => {
            this.setState({ bookedTimes: res.data.data });
            this.getTimes(selDate, session, santName1);
        }, (err) => {

        });
    }

    getTimes(newDate, session, santName1) {
        let timeSlot = this.props.padharamniList.filter(padharamni => padharamni.Date === newDate && padharamni.Session === session && padharamni.SantName1 === santName1)[0].Time.split("-").map(function (item) {
            return item.trim();
        });
        // let all_times = ["9:00 AM", "9:10 AM", "9:20 AM", "9:30 AM", "9:40 AM", "9:50 AM", "10:00 AM", "10:10 AM", "10:20 AM", "10:30 AM", "10:40 AM", "10:50 AM", "11:00 AM", "11:10 AM", "11:20 AM", "11:30 AM", "11:40 AM", "11:50 AM", "12:00 PM", "12:10 PM", "12:20 PM", "12:30 PM", "12:40 PM", "12:50 PM", "1:00 PM", "1:10 PM", "1:20 PM", "1:30 PM", "1:40 PM", "1:50 PM", "2:00 PM", "2:10 PM", "2:20 PM", "2:30 PM", "2:40 PM", "2:50 PM", "3:00 PM", "3:10 PM", "3:20 PM", "3:30 PM", "3:40 PM", "3:50 PM", "4:00 PM", "4:10 PM", "4:20 PM", "4:30 PM", "4:40 PM", "4:50 PM", "5:00 PM", "5:10 PM", "5:20 PM", "5:30 PM", "5:40 PM", "5:50 PM", "6:00 PM", "6:10 PM", "6:20 PM", "6:30 PM", "6:40 PM", "6:50 PM", "7:00 PM", "7:10 PM", "7:20 PM", "7:30 PM", "7:40 PM", "7:50 PM", "8:00 PM", "8:10 PM", "8:20 PM", "8:30 PM", "8:40 PM", "8:50 PM", "9:00 PM", "9:10 PM", "9:20 PM", "9:30 PM", "9:40 PM", "9:50 PM", "10:00 PM"]
        let all_times = ["8:00 AM", "8:20 AM", "8:40 AM", "9:00 AM", "9:20 AM", "9:40 AM", "10:00 AM", "10:20 AM", "10:40 AM", "11:00 AM", "11:20 AM", "11:40 AM", "12:00 PM", "12:20 PM", "12:40 PM", "1:00 PM", "1:20 PM", "1:40 PM", "2:00 PM", "2:20 PM", "2:40 PM", "3:00 PM", "3:20 PM", "3:40 PM", "4:00 PM", "4:20 PM", "4:40 PM", "5:00 PM", "5:20 PM", "5:40 PM", "6:00 PM", "6:20 PM", "6:40 PM", "7:00 PM", "7:20 PM", "7:40 PM", "8:00 PM", "8:20 PM", "8:40 PM", "9:00 PM", "9:20 PM", "9:40 PM", "10:00 PM"]
        let timeRange = all_times.slice(all_times.indexOf(timeSlot[0]), all_times.indexOf(timeSlot[1]));
        this.setState({ availTimes: timeRange, hideTimes: false })
    }

    getSessions(newDate) {
        let sessions = this.props.padharamniList.filter(padharamni => padharamni.Date === newDate);
        this.setState({ availSessions: sessions, hideSession: false })
        if (this.state.editFamilyDetails.Session && this.state.editFamilyDetails.Session.length > 1) {
            this.getBookedTimes(this.state.editFamilyDetails.Date, this.state.editFamilyDetails.Session, this.state.editFamilyDetails.SantName1);
        }
        else if (sessions.length === 1) {
            this.changeValue(sessions[0].Session + "|" + sessions[0].SantName1 + "|" + sessions[0].Id, 2);
        }
    }

    handleResetValues() {
        this.setState({
            showEditModal: false,
            editFamilyDetails: {},
            hideTimes: true,
            availTimes: [],
            bookedTimes: [],
            registerDisableBtn: false,
            registerWarningText: "",
            registerShowWarning: false,
            deleteSelected: false
        });
    }

    changeValue(value, changeItem, event = {}) {
        let familyDetails = this.state.editFamilyDetails;
        if (changeItem === 1) { //date
            this.setState({ hideTimes: true, hideSession: true });
            familyDetails.Date = value;
            familyDetails.SelectedTime = "";
            familyDetails.Session = "";
            familyDetails.SantName1 = "";
            familyDetails.SantName2 = "";
            familyDetails.PadhramniAvailabilityId = "";
            if (value !== "delete") {
                this.setState({deleteSelected: false});
                this.getSessions(value);
            }
            else {
                this.setState({deleteSelected: true});
            }
        } else if (changeItem === 2) { // session
            this.setState({ hideTimes: true });
            familyDetails.Session = value.split("|")[0];
            familyDetails.SantName1 = value.split("|")[1];
            familyDetails.SantName2 = this.props.padharamniList.filter(padharamni => padharamni.Date === familyDetails.Date && padharamni.Session === familyDetails.Session && padharamni.SantName1 === familyDetails.SantName1)[0].SantName2;
            familyDetails.PadhramniAvailabilityId = value.split("|")[2];
            familyDetails.SelectedTime = "";
            this.getBookedTimes(familyDetails.Date, familyDetails.Session, familyDetails.SantName1);
        } else if (changeItem === 3) { //time
            familyDetails.SelectedTime = value;
        } else if (changeItem === 4) { //Cancel Reason
            familyDetails.CancelReason = value;
        } else { //notes
            familyDetails.Notes = value;
        }
        this.setState({ editFamilyDetails: familyDetails });
    }

    handleDelete() {
        let unfilledFields = this.state.editFamilyDetails.CancelReason.length < 1;
        if (unfilledFields === true) {
            this.setState({ registerWarningText: "Required fields are missing.", registerShowWarning: true });
            return;
        }
        // let url = `http://localhost:5004/api/padharamni`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni` // Production
        let params = {
            eventName: this.props.padharamniList[0].EventName,
            personId: this.state.editFamilyDetails.PersonId,
            reason: this.state.editFamilyDetails.CancelReason,
            notes: this.state.editFamilyDetails.Notes
        }
        this.setState({ registerDisableBtn: true });
        axios.delete(url, { params }).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.handleClose(true);
            }
            else {
                this.setState({ registerDisableBtn: false });
                this.setState({ registerWarningText: "An error occured while sending your information. Please try again.", registerShowWarning: true });
            }
        });
    }

    handleRegister() {
        if (this.state.editFamilyDetails.Date === "delete") {
            this.handleDelete();
            return;
        }

        // Check fields are filled
        let unfilledFields = this.state.editFamilyDetails.Date.length < 1 || this.state.editFamilyDetails.SelectedTime.length < 1;
        if (unfilledFields === true) {
            this.setState({ registerWarningText: "Required fields are missing.", registerShowWarning: true });
            return;
        }

        // let url = `http://localhost:5004/api/padharamni`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni` // Production
        let body = {
            eventName: this.props.padharamniList[0].EventName,
            personId: this.state.editFamilyDetails.PersonId,
            padharamniId: parseInt(this.state.editFamilyDetails.PadhramniAvailabilityId),
            selectedTime: this.state.editFamilyDetails.SelectedTime,
            padharamniStatus: "scheduled",
            notes: this.state.editFamilyDetails.Notes
        }
        this.setState({ registerDisableBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.handleClose(true);
            }
            else {
                this.setState({ registerDisableBtn: false });
                this.setState({ registerWarningText: "An error occured while sending your information. Please try again.", registerShowWarning: true });
            }
        });
    }

    handleSortChange(value) {
        this.setState({ familyFilterValue: value === 'zipcode' || value === 'default' ? '' : 'All' },
        () => {
            localStorage.setItem('filterFamilyValue', this.state.familyFilterValue);
            localStorage.setItem('sortValue', value);
            this.props.refreshList(this.props.padharamniList[0].ZoneName, value);
        });
    }

    render() {
        return (
            <Accordion defaultActiveKey="familyTable">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="familyTable">
                        Schedule Padharamnis
                        <span></span>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="familyTable">
                        <Card.Body className="familyListCard">
                            <Table striped className="padharamniTable tableDesktop">
                                <thead className="filterResults">
                                    <tr>
                                        <th colSpan={2}>
                                            <InputGroup>
                                                {this.state.sortValue === 'so' ? <Form.Control as="select" disabled={false} value={this.state.familyFilterValue} onChange={(event) => this.filterFamily(event.target.value)}>
                                                    <option>All</option>
                                                    <option>Unassigned</option>
                                                    <option disabled>-----------------</option>
                                                    {[...new Set(this.props.tableList.map(family => family.SOKaryakar))].map((karyakar) => karyakar && (
                                                        <option key={karyakar} value={karyakar}>{karyakar}</option>
                                                    ))}
                                                </Form.Control>:
                                                <Form.Control placeholder="Search by name" value={this.state.familyFilterValue} onChange={(event) => this.filterFamily(event.target.value)} />
                                                }
                                                <DropdownButton
                                                variant="outline-secondary"
                                                title={this.state.sortValue === 'default' ? <SortAlphaDown className="copyLink"></SortAlphaDown> : this.state.sortValue === 'zipcode' ? <SortNumericDown className="copyLink"></SortNumericDown> : <PersonFillAdd className="copyLink"></PersonFillAdd>}
                                                id="input-group-dropdown-2"
                                                align="end"
                                                >
                                                <Dropdown.Item href={void(0)} onClick={() => this.handleSortChange('default')}>By Default</Dropdown.Item>
                                                <Dropdown.Item href={void(0)} onClick={() => this.handleSortChange('zipcode')}>By Zipcode</Dropdown.Item>
                                                {this.props.selEvent.showSOSorting && 
                                                    <>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item href={void(0)} onClick={() => this.handleSortChange('so')}>By SO Karyakar</Dropdown.Item>
                                                    </>
                                                }
                                                
                                                </DropdownButton>
                                            </InputGroup>
                                        </th>
                                        <th>
                                            {this.state.filteredFamilyList.length} of {this.props.tableList.length} items
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Family Details</th>
                                        <th>Padharamni Info</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filteredFamilyList.map((family) => (
                                        <tr key={family.personId}>
                                            <td>
                                                <b>Name:</b> {family.FirstName + " " + family.LastName} <ViewFamilyMembers personId={family.PersonId} selEvent={this.props.selEvent}></ViewFamilyMembers><br />
                                                <b>Phone:</b> <a href={`tel:${family.Cell}`}>{family.Cell}</a><br />
                                                <b>Email:</b> {family.Email}<br />
                                                {family.Source && family.Source.length > 0 && <><b>Source:</b> {family.Source}<br /></>}
                                                <b>Address:</b> <a rel="noreferrer" href={`https://maps.google.com/?q=${family.Address}, ${family.City}, ${family.State}, ${family.Zipcode}`} target="_blank">{family.Address + ", " + family.City}</a><br />
                                                {this.state.sortValue === 'zipcode' && <><b>Zipcode:</b> {family.Zipcode}<br /></>}
                                            </td>
                                            <td>
                                                {family.PadharamniStatus === "cancelled" ?
                                                    <><b>Cancelled:</b> {family.CancelReason}<br /></>
                                                : <>
                                                    <b>Date:</b> {family.Date ? moment.utc(family.Date).format('dddd').substring(0, 3) + ", " + moment.utc(family.Date).format('LL') : ""}<br />
                                                    <b>Time:</b> {family.SelectedTime}<br />
                                                    <b>Sant:</b> {family.SantName1}<br />
                                                </>}
                                                <b>Notes:</b> {family.PastNotes && family.PastNotes > 0 ? <ViewPastNotes personId={family.PersonId} selEvent={this.props.selEvent}></ViewPastNotes> : ""} {family.Notes}
                                            </td>
                                            <td><button onClick={() => this.editFamily(family)}><img src={pencil_icon} alt="Edit family details" /></button></td>
                                        </tr>
                                    ))}
                                    {this.state.filteredFamilyList.length === 0 &&
                                        <tr>
                                            <td colSpan={3} className="tableNoResults">No results to display</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <Table striped className="padharamniTable tableMobile">
                                <thead className="filterResults">
                                    <tr>
                                        <th colSpan={2}>
                                            <InputGroup>
                                                {this.state.sortValue === 'so' ? <Form.Control as="select" disabled={false} value={this.state.familyFilterValue} onChange={(event) => this.filterFamily(event.target.value)}>
                                                    <option>All</option>
                                                    <option>Unassigned</option>
                                                    <option disabled>-----------------</option>
                                                    {[...new Set(this.props.tableList.map(family => family.SOKaryakar))].map((karyakar) => karyakar && (
                                                        <option key={karyakar} value={karyakar}>{karyakar}</option>
                                                    ))}
                                                </Form.Control>:
                                                <Form.Control placeholder="Search by name" value={this.state.familyFilterValue} onChange={(event) => this.filterFamily(event.target.value)} />
                                                }
                                                <DropdownButton
                                                variant="outline-secondary"
                                                title={this.state.sortValue === 'default' ? <SortAlphaDown className="copyLink"></SortAlphaDown> : this.state.sortValue === 'zipcode' ? <SortNumericDown className="copyLink"></SortNumericDown> : <PersonFillAdd className="copyLink"></PersonFillAdd>}
                                                id="input-group-dropdown-2"
                                                align="end"
                                                >
                                                <Dropdown.Item href={void(0)} onClick={() => this.handleSortChange('default')}>By Default</Dropdown.Item>
                                                <Dropdown.Item href={void(0)} onClick={() => this.handleSortChange('zipcode')}>By Zipcode</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item href={void(0)} onClick={() => this.handleSortChange('so')}>By SO Karyakar</Dropdown.Item>
                                                </DropdownButton>
                                            </InputGroup>
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Family Details</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filteredFamilyList.map((family) => (
                                        <tr key={family.personId}>
                                            <td>
                                                <b>Name:</b> {family.FirstName + " " + family.LastName} <ViewFamilyMembers personId={family.PersonId} selEvent={this.props.selEvent}></ViewFamilyMembers><br />
                                                <b>Phone:</b> <a href={`tel:${family.Cell}`}>{family.Cell}</a><br />
                                                <b>Email:</b> {family.Email}<br />
                                                {family.Source && family.Source.length > 0 && <><b>Source:</b> {family.Source}<br /></>}
                                                <b>Address:</b> <a rel="noreferrer" href={`https://maps.google.com/?q=${family.Address}, ${family.City}, ${family.State}, ${family.Zipcode}`} target="_blank">{family.Address + ", " + family.City}</a><br />
                                                {this.state.sortValue === 'zipcode' && <><b>Zipcode:</b> {family.Zipcode}<br /></>}
                                                <br/>
                                                {family.PadharamniStatus === "cancelled" ?
                                                    <><b>Cancelled:</b> {family.CancelReason}<br /></>
                                                : <>
                                                    <b>Date:</b> {family.Date ? moment.utc(family.Date).format('dddd').substring(0, 3) + ", " + moment.utc(family.Date).format('LL') : ""}<br />
                                                    <b>Time:</b> {family.SelectedTime}<br />
                                                    <b>Sant:</b> {family.SantName1}<br />
                                                </>}
                                                <b>Notes:</b> {family.PastNotes && family.PastNotes > 0 ? <ViewPastNotes personId={family.PersonId} selEvent={this.props.selEvent}></ViewPastNotes> : ""} {family.Notes}

                                            </td>
                                            <td><button onClick={() => this.editFamily(family)}><img src={pencil_icon} alt="Edit family details" /></button></td>
                                        </tr>
                                    ))}
                                    {this.state.filteredFamilyList.length === 0 &&
                                        <tr>
                                            <td colSpan={2} className="tableNoResults">No results to display</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Modal show={this.state.showEditModal} onHide={() => this.handleClose()} size="md" className="modalCont" scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>
                                <b>Name:</b> {this.state.editFamilyDetails.FirstName + " " + this.state.editFamilyDetails.LastName}<br />
                                <b>Address:</b> {this.state.editFamilyDetails.Address + ", " + this.state.editFamilyDetails.City}<br />
                            </p>
                            {this.state.registerShowWarning ? <Alert variant={"danger"}>{this.state.registerWarningText}</Alert> : ""}
                            <Form.Group className="mb-3">
                                <Form.Label>Select a date from below:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.editFamilyDetails.Date} onChange={(event) => this.changeValue(event.target.value, 1, event)}>
                                    <option className="hideOption"></option>
                                    {[...new Set(this.props.padharamniList.map(padharamni => padharamni.Date))].map((date) => (
                                        <option key={date} value={date}>{moment.utc(date).format('dddd').substring(0, 3) + ", " + moment.utc(date).format('LL')}</option>
                                    ))}
                                    <option disabled></option>
                                    <option disabled>----------------</option>
                                    <option value={"delete"}>Not available for Padharamni?</option>
                                </Form.Control>
                            </Form.Group>
                            {!this.state.deleteSelected && <><Form.Group className="mb-3">
                                <Form.Label>Select a session from below:<sup>*</sup></Form.Label>
                                <Form.Control as="select" disabled={this.state.hideSession} value={this.state.editFamilyDetails.Session + "|" + this.state.editFamilyDetails.SantName1 + "|" + this.state.editFamilyDetails.PadhramniAvailabilityId} onChange={(event) => this.changeValue(event.target.value, 2, event)}>
                                    <option className="hideOption"></option>
                                    {this.state.availSessions.map((padharamniDate) => (
                                        <option key={padharamniDate.Session + "|" + padharamniDate.SantName1 + "|" + padharamniDate.Id} value={padharamniDate.Session + "|" + padharamniDate.SantName1 + "|" + padharamniDate.Id}>{padharamniDate.Session} with {padharamniDate.SantName1}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select a time from below:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.editFamilyDetails.SelectedTime} disabled={this.state.hideTimes} onChange={(event) => this.changeValue(event.target.value, 3, event)}>
                                    <option className="hideOption"></option>
                                    {this.state.availTimes.map((time) => (
                                        <option key={time} disabled={this.state.bookedTimes.filter((booking) => (booking.SelectedTime === time)).length > 0}>{time}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group></>}
                            {this.state.deleteSelected && <Form.Group className="mb-3">
                                <Form.Label>Cancellation Reason:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.editFamilyDetails.CancelReason} onChange={(event) => this.changeValue(event.target.value, 4, event)}>
                                    <option className="hideOption"></option>
                                    {this.props.selEvent.cancelReasons.map((reason) => (
                                        <option key={reason}>{reason}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>}
                            <Form.Group className="mb-3">
                                <Form.Label>Notes:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={this.state.editFamilyDetails.Notes}
                                    onChange={(event) => this.changeValue(event.target.value, 5, event)}
                                />
                            </Form.Group>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                        <div>
                            <Button variant="primary" onClick={() => this.handleRegister()} disabled={this.state.registerDisableBtn}>
                                {this.state.deleteSelected ? "Cancel Now" : "Book Now"}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Accordion>

        );
    }
}

export default withParams(PadharamniFamilyList);
