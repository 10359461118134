// Vendor Files
import React from "react";
import { Button, Table, Modal, Card, Form, Alert } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { useParams } from "react-router";
import { PeopleFill } from 'react-bootstrap-icons';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ViewFamilyMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFamilyModal: false,
            familyMembers: [],
            lastMemberIndex: -1
        };
    }

    fetchFamily() {
        let personId = this.props.personId;
        // let url = `http://localhost:5004/api/padharamni/familyMembers`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/familyMembers` // Production
        let params = {
            personId: personId,
            familyNotes: this.props.santTable ? '1' : '0',
            eventName: this.props.selEvent?.name || this.props.eventName
        }
        axios.get(url, { params }).then((res) => {
            this.setState({ familyMembers: res.data.data });
        }, (err) => {

        });
    }

    handleOpen() {
        this.setState({ showFamilyModal: true, lastMemberIndex: -1 });
        this.fetchFamily();
    }

    handleClose() {
        this.handleSave(this.state.lastMemberIndex);
        this.setState({ showFamilyModal: false });
    }

    checkValue(member, value) {
        if (member.EventQuestions && member.EventQuestions.split('|').indexOf(value) >= 0) {
            return true;
        }
        else {
            return false;
        }
    }

    handleNoteChange(value, memberIndex) {
        let newFamilyMembers = this.state.familyMembers;
        newFamilyMembers[memberIndex].Notes = value;
        this.setState({familyMembers: newFamilyMembers});
    }

    handleCheckboxes(event, checkbox_name, memberIndex) {
        let checkbox_status = this.state.familyMembers[memberIndex].EventQuestions ? this.state.familyMembers[memberIndex].EventQuestions.split('|') : [];
        if (event.target.checked) {
            checkbox_status.push(checkbox_name);
        }
        else {
            checkbox_status.splice(checkbox_status.indexOf(checkbox_name), 1)
        }
        let newFamilyMembers = this.state.familyMembers;
        newFamilyMembers[memberIndex].EventQuestions = checkbox_status.join('|');
        this.setState({familyMembers: newFamilyMembers});
    }

    handleSave(memberIndex) {
        if (this.state.lastMemberIndex === -1) {
            this.setState({lastMemberIndex: memberIndex});
            return;
        }

        if (!this.state.familyMembers[this.state.lastMemberIndex].EventQuestions && !this.state.familyMembers[this.state.lastMemberIndex].Notes && !this.state.familyMembers[this.state.lastMemberIndex].DataExist) {
            this.setState({lastMemberIndex: memberIndex});
            return;
        }
        
        // let url = `http://localhost:5004/api/padharamni/familyMembers`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/familyMembers` // Production
        let body = {
            eventName: this.props.eventName,
            personId: this.state.familyMembers[this.state.lastMemberIndex].PersonId,
            questions: this.state.familyMembers[this.state.lastMemberIndex].EventQuestions ? this.state.familyMembers[this.state.lastMemberIndex].EventQuestions : '',
            notes: this.state.familyMembers[this.state.lastMemberIndex].Notes ? this.state.familyMembers[this.state.lastMemberIndex].Notes : '',
        }
        this.setState({ registerDisableBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.setState({lastMemberIndex: memberIndex});
            }
        });
    }

    handleSanyuktaCategory(value, member) {
        let memberIndex = this.state.familyMembers.findIndex((family) => (family.PersonId === member.PersonId));
        let familyMembers = this.state.familyMembers;
        familyMembers[memberIndex].SanyuktaCategory = value;
        this.setState({ familyMembers: familyMembers });
        // let url = `http://localhost:5004/api/padharamni/familyMembers`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/familyMembers` // Production
        let body = {
            personId: member.PersonId,
            sanyuktaCategory: value
        }
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                return;
            }
        });
    }

    render() {
        return (
            <>
                <PeopleFill className="copyLink" onClick={() => this.handleOpen()}></PeopleFill>
                <Modal show={this.state.showFamilyModal} onHide={() => this.handleClose()} size="md" className={`modalCont ${this.props.santTable ? 'santAccordion' : ''}`} scrollable={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Table striped className="padharamniTable">
                            <tbody>
                                <Accordion className="familyAccordion">
                                {this.props.santTable &&
                                    <Alert variant={"primary"} >Note: Your changes will be autosaved.</Alert>
                                }
                                {this.state.familyMembers.map((member, index) => (
                                    <tr key={member.PersonId}>
                                        <td>
                                                {this.props.santTable ? <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={member.PersonId} onClick={() => this.handleSave(index)}>
                                                        <b>Name:</b> {member.FirstName + " " + member.LastName} ({member.MandalName})<br />
                                                        <b>Relation:</b> {member.RelationType}<br />
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={member.PersonId}>
                                                        <Card.Body>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Select ahniks they are currently doing:</Form.Label>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check1${member.PersonId}`} checked={this.checkValue(member, 'Arti at home')} onChange={(event) => this.handleCheckboxes(event, "Arti at home", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check1${member.PersonId}`}>Arti at home</Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check7${member.PersonId}`} checked={this.checkValue(member, 'Chesta')} onChange={(event) => this.handleCheckboxes(event, "Chesta", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check7${member.PersonId}`}>Chesta</Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Label>Nitya Puja:</Form.Label>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check2${member.PersonId}`} checked={this.checkValue(member, 'Puja with Tilak Chandlo')} onChange={(event) => this.handleCheckboxes(event, "Puja with Tilak Chandlo", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check2${member.PersonId}`}>Puja with Tilak Chandlo</Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check3${member.PersonId}`} checked={this.checkValue(member, 'Puja without Tilak Chandlo')} onChange={(event) => this.handleCheckboxes(event, "Puja without Tilak Chandlo", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check3${member.PersonId}`}>Puja without Tilak Chandlo</Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Label>Nitya Vanchan:</Form.Label>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check4${member.PersonId}`} checked={this.checkValue(member, 'Vachnamrut')} onChange={(event) => this.handleCheckboxes(event, "Vachnamrut", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check4${member.PersonId}`}>Vachnamrut</Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check5${member.PersonId}`} checked={this.checkValue(member, 'Swami ni Vaato')} onChange={(event) => this.handleCheckboxes(event, "Swami ni Vaato", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check5${member.PersonId}`}>Swami ni Vaato</Form.Check.Label>
                                                                </Form.Check>
                                                                <Form.Check>
                                                                    <Form.Check.Input id={`check6${member.PersonId}`} checked={this.checkValue(member, 'Jivan Charitra')} onChange={(event) => this.handleCheckboxes(event, "Jivan Charitra", index)} type="checkbox" />
                                                                    <Form.Check.Label for={`check6${member.PersonId}`}>Jivan Charitra</Form.Check.Label>
                                                                </Form.Check>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Notes:</Form.Label>
                                                                <Form.Control
                                                                    value={member.Notes ? member.Notes : ''}
                                                                    onChange={(event) => this.handleNoteChange(event.target.value, index)}
                                                                    type="text"
                                                                    as="textarea"
                                                                />
                                                            </Form.Group>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card> :
                                            <>
                                                <b>Name:</b> {member.FirstName + " " + member.LastName} ({member.MandalName})<br />
                                                <b>Relation:</b> {member.RelationType}<br />
                                                {member.MandalName === "Sanyukta" && this.props.selEvent.showSanyuktaCategory &&
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Sanyukta Category:<sup>*</sup></Form.Label>
                                                        <Form.Control as="select" value={member.SanyuktaCategory ? member.SanyuktaCategory : ''} onChange={(event) => this.handleSanyuktaCategory(event.target.value, member)}>
                                                            <option className="hideOption"></option>
                                                            <option>Young Sanyukta (30 - 40 years of age, attended kishore/yuvak sabha in USA)</option>
                                                            <option>Sanyukta (30 - 60 years of age, came from India as sanyukta)</option>
                                                            <option>Vadil Sanyukta (60+ years of age)</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                }
                                            </>}
                                        </td>
                                    </tr>
                                ))}
                                </Accordion>
                            </tbody>
                        </Table>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            
        );
    }
}

export default withParams(ViewFamilyMembers);
