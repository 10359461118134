// Vendor Files
import React from "react";
import { Row, Col, Button, Form, Alert, Spinner, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";
import { Eye, Pencil } from 'react-bootstrap-icons';

// Custom Components
import PadharamniFamilyList from "./PadharamniFamilyList";
import PadharamniViewList from "./PadharamniViewList";
import PadharamniZoneList from "./PadharamniZoneList";

// Events List
import { zoneList } from './zoneList';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PadharamniSchedular extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoneList: [],
            showInputWarning: false,
            inputWarningText: "",
            zoneSelection: "",
            zoneInput: "",
            pinInput: "",
            showEditModal: false,
            editFamilyDetails: {},
            showLoader: false,
            familyList: [],
            padharamniList: [],
            familyFilterValue: "",
            mode: "edit"
        };
        this.getFamilies = this.getFamilies.bind(this);
        this.getPadharamnis = this.getPadharamnis.bind(this);
        this.getViewPadharamnis = this.getViewPadharamnis.bind(this);
    }

    componentDidMount() {
        this.getEvent();
        localStorage.removeItem('filterFamilyValue');
    }

    getEvent() {
        this.setState({ zoneList: zoneList[this.props.selEvent.center] });
    }

    checkZonePin() {
        this.setState({ inputWarningText: "", showInputWarning: false });
        if (this.state.zoneInput.length < 1 || this.state.pinInput.length < 1) {
            this.setState({ inputWarningText: "Both fields are required.", showInputWarning: true });
            return;
        }
        let actualPin = this.state.zoneList.filter(zone => zone.id === this.state.zoneInput)[0].pin;
        if (this.state.pinInput === actualPin) {
            this.getFamilies(this.state.zoneInput);
        }
        else {
            this.setState({ inputWarningText: "Incorrect pin, please try again.", showInputWarning: true });
            return;
        }
    }

    getFamilies(zoneName, sortValue = 'default') {
        // let url = `http://localhost:5004/api/padharamni/families`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/families` // Production
        this.setState({ showLoader: true });
        let params = {
            centerName: this.props.selEvent.center,
            zoneName: zoneName,
            eventName: this.props.selEvent.name,
            sortValue: sortValue
        }
        axios.get(url, { params }).then((res) => {
            this.setState({ familyList: res.data.data }, () => {
                this.getPadharamnis(zoneName);
            });
        }, (err) => {

        });
    }

    getPadharamnis(zoneName) {
        // let url = `http://localhost:5004/api/padharamni/availabilities`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/availabilities` // Production
        this.setState({ showLoader: true });
        let params = {
            centerName: this.props.selEvent.center,
            zoneName: zoneName,
            eventName: this.props.selEvent.name,
        }
        axios.get(url, { params }).then((res) => {
            this.setState({ showLoader: false, padharamniList: res.data.data });
            this.setState({ inputWarningText: "", showInputWarning: false, zoneSelection: this.state.zoneInput, pinInput: "" });
        }, (err) => {

        });
    }

    getViewPadharamnis() {
        // let url = `http://localhost:5004/api/padharamni/view/zone`  // Dev testing
        let url = `https://mw-chicago-mandir-backend.web.app/api/padharamni/view/zone` // Production
        let params = {
            centerName: this.props.selEvent.center,
            zoneName: this.state.zoneSelection,
            eventName: this.props.selEvent.name,
        }
        this.setState({ showLoader: true, mode: "" });
        axios.get(url, { params }).then((res) => {
            this.setState({ familyList: res.data.data.familyList, padharamniList: res.data.data.padharamniList });
            this.setState({ showLoader: false, mode: "view" });
        }, (err) => {

        });
    }

    viewSwitcher(num) {
        localStorage.removeItem('filterFamilyValue');
        if (num === 1) {
            this.setState({ mode: "edit" });
        }
        else {
            this.setState({ mode: "view" });
        }
    }

    render() {
        return (
            <>
                <Row className="zoneDropdownCont mb-4">
                    <Col md="6">
                        <Form.Group className="mb-3 mt-3">
                            <Form.Control as="select" value={this.state.zoneInput} onChange={(event) => this.setState({ zoneInput: event.target.value })}>
                                <option disabled value="">Select your zone:</option>
                                {this.state.zoneList.map(zone => {
                                    return (
                                        <option key={zone.id}>{zone.id}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md="4">
                        <Form.Group className="mb-3 mt-3 pin-input">
                            <Form.Control
                                placeholder="Enter your pin"
                                value={this.state.pinInput}
                                type="password"
                                onChange={(event) => this.setState({ pinInput: event.target.value })}
                                onKeyDown={(event) => event.key === 'Enter' && this.checkZonePin(true)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="2">
                        <Button variant="primary btn-brand mb-3 mt-3" onClick={() => this.checkZonePin()}>
                            Search
                        </Button>
                    </Col>
                </Row>
                {this.state.showInputWarning ? <Alert variant={"danger"}>{this.state.inputWarningText}</Alert> : ""}
                <br /><br />
                {this.state.showLoader &&
                    <Spinner animation="border" role="status">
                    </Spinner>
                }
                {(this.state.familyList.length === 0 || this.state.padharamniList.length === 0) && !this.state.showLoader && this.state.zoneSelection.length > 1 && <p>No results to display.</p>}
                {this.state.familyList.length > 0 && this.state.padharamniList.length > 0 && !this.state.showLoader && <div>
                    <div className="viewEditCont">
                        <h2>{this.state.zoneSelection}</h2>
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="secondary" className={this.state.mode === "edit" && "active"} onClick={() => this.viewSwitcher(1)}><Pencil /></Button>
                            <Button variant="secondary" className={this.state.mode !== "edit" && "active"} onClick={() => this.viewSwitcher(2)}><Eye /></Button>
                        </ButtonGroup>
                    </div>

                    {this.state.mode === "edit" &&
                        <div>
                            <PadharamniZoneList  selEvent={this.props.selEvent} tableList={this.state.padharamniList} refreshList={this.getPadharamnis}></PadharamniZoneList>
                            <br />
                            <PadharamniFamilyList  selEvent={this.props.selEvent} tableList={this.state.familyList} padharamniList={this.state.padharamniList} refreshList={this.getFamilies}></PadharamniFamilyList>
                        </div>
                    }
                    {this.state.mode === "view" &&
                        <div>
                            <PadharamniViewList  selEvent={this.props.selEvent} tableList={this.state.familyList} padharamniList={this.state.padharamniList} refreshList={this.getViewPadharamnis}></PadharamniViewList>
                        </div>
                    }
                </div>}
            </>
        );
    }
}

export default withParams(PadharamniSchedular);
